<template>
  <div class="page page-tools-home">
    <div class="container heading">
      <div class="row">
        <div class="col">
          <h1>My toolbox</h1>
          <hr />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="tools">
            <div class="tool" v-for="tool in tools" :key="tool.id">
              <a :href="'/' + $i18n.locale + '/tools/' + tool.url">
                <div class="tool-body">
                  <i class="tool-icon" :class="tool.icon" v-if="tool.icon"></i>
                  <i
                    class="tool-icon fa-solid fa-toolbox"
                    v-if="!tool.icon"
                  ></i>
                  <h5 class="tool-title">{{ tool.name }}</h5>
                  <p class="tool-text">{{ tool.content }}</p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ToolsHome',
  data: function () {
    return {
      tools: [
        {
          id: 'randomizer',
          icon: 'fa-solid fa-shuffle',
          name: 'Randomizer',
          content:
            '#RandomText #PasswordGenerator #StringRandomizer #Javascript',
          url: 'randomizer'
        },
        {
          id: 'ip-locator',
          icon: 'fa-solid fa-location-crosshairs',
          name: 'Get IP Location',
          content: '#IPAddress #IPLocation #ipgeolocation',
          url: 'ip-locator'
        },
        {
          id: 'color-picker',
          icon: 'fa-solid fa-eye-dropper',
          name: 'Color Picker',
          content: '#colorPicker #colorDesign #colorHarmonize',
          url: 'color-picker'
        },
        {
          id: 'more-app',
          icon: 'fa-solid fa-ellipsis',
          name: 'More App',
          content: 'Under Construction',
          url: '#'
        }
      ]
    }
  },
  methods: {},
  mounted () {}
}
</script>

<style scoped lang="scss">
@import '@/assets/variables.scss';
@import '@/assets/toolshead.scss';

h1 {
  margin-bottom: 10px;
  text-align: left;
  color: $key-yellow;
  font-size: 2.8em;
  font-weight: 700;
}
.tools {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  .tool a {
    display: block;
    padding: 1em;
    color: #a5cd96;
    border: 2px dashed;
    border-radius: 5px;
    background-color: #292926;
    box-shadow: 8px 8px 0 #21211e;
    text-align: center;
    text-decoration: none;
    &:hover {
      border-style: solid;
    }
    .tool-body {
      .tool-icon {
        font-size: 3em;
      }
      .tool-title {
      }
      .tool-text {
        font-size: 0.8em;
        font-style: italic;
        text-align: left;
      }
    }
  }
}
@media (max-width: 768px) {
  .tools {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 576px) {
  .tools {
    grid-template-columns: repeat(1, 1fr);
  }
}
</style>
